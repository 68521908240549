import AppStoreDownloadLogo from '@images/appStoreDownload.svg'
import GymJunkieLogo from '@images/gymJunkieLogo.svg'
import PlayStoreDownloadLogo from '@images/playstoreDownload.png'
import { Layout } from '@styles/layout'
import { MOBILE_BREAK_POINT } from '@styles/styles'
import React from 'react'
import styled from 'styled-components'
import { HomeBackground } from './components/homeBackground'

export const Home = () => {
  return (
    <Layout>
      <Wrapper>
        <HomeBackground />
        <BodyWrapper>
          <BodyContainer>
            <Logo src={GymJunkieLogo} />
            <TitleText>
              Gym Junkie <TextHighlight>Web</TextHighlight>
            </TitleText>
            <SubText>COMING SOON</SubText>
            <DownloadsWrapper>
              <SmallerSubText>
                GET THE
                <TextHighlight> APP</TextHighlight> NOW
              </SmallerSubText>
              <DownloadButtonsWrapper>
                <DownloadButton left>
                  <DownloadButtonImage src={AppStoreDownloadLogo} />
                </DownloadButton>
                <DownloadButton
                  href={
                    'https://play.google.com/store/apps/details?id=com.gymjunkieapp.gymjunkie'
                  }
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <DownloadButtonImage src={PlayStoreDownloadLogo} playstore />
                </DownloadButton>
              </DownloadButtonsWrapper>
            </DownloadsWrapper>
          </BodyContainer>
        </BodyWrapper>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  /* border: 1px solid red; */
  overflow: hidden;
`

const BodyWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-image: radial-gradient(
    rgba(0, 0, 0, 0.4) 30%,
    rgba(0, 0, 0, 1) 85%
  );
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`

const Logo = styled.img`
  width: 12em;
  height: auto;
  object-fit: contain;
  margin-bottom: 2em;

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    width: 40vw;
  }
`

const TitleText = styled.h1`
  color: ${(props) => props.theme.colors.text1};
  font-size: ${(props) => props.theme.fontSize.desktop.header}em;
  font-weight: 600;
  text-shadow: 0px 3px 2px #000;

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.fontSize.mobile.header}em;
  }
`

interface TextHighlightProps {
  primary?: boolean
}

const TextHighlight = styled.span<TextHighlightProps>`
  color: ${(props) =>
    props.primary ? props.theme.colors.primary : props.theme.colors.secondary};
`

const SubText = styled.h2`
  color: ${(props) => props.theme.colors.text2};
  font-size: ${(props) => props.theme.fontSize.desktop.body}em;
  font-weight: 300;
  margin-top: 0.2em;
  text-shadow: 0px 2px 1px #000;

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.fontSize.mobile.body}em;
  }
`

const SmallerSubText = styled.h3`
  color: ${(props) => props.theme.colors.text2};
  font-size: ${(props) => props.theme.fontSize.desktop.body}em;
  font-weight: 600;
  text-shadow: 0px 3px 2px #000;

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.fontSize.mobile.body}em;
  }
`

const DownloadsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
`

const DownloadButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5em;
`

interface DownloadButtonProps {
  left?: boolean
}

const DownloadButton = styled.a<DownloadButtonProps>`
  width: 15em;
  text-align: ${(props) => (props.left ? 'right' : 'left')};
  /* margin-horizontal: 1em; */
  /**To compensate for the margin the playstore button image has by default =/ */
  padding-right: ${(props) => (props.left ? 2 : 0)}%;
  /* border: 1px solid lightpink; */
  cursor: pointer;
  transition: opacity 500ms;
  &:hover {
    opacity: 0.8;
  }
  /* border: 1px solid lightsalmon; */

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    width: 30vw;
  }
`

interface DownloadButtonImageProps {
  playstore?: boolean
}

const DownloadButtonImage = styled.img<DownloadButtonImageProps>`
  height: ${(props) => (props.playstore ? 5 : 3.35)}em;
  width: auto;

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    height: ${(props) => (props.playstore ? 14 : 10)}vw;
    width: auto;
  }
`
