import '@fontsource/jura'
import '@fontsource/montserrat'
import React from 'react'
import { createGlobalStyle, css, ThemeProvider } from 'styled-components'
import { theme } from './styles'

const GlobalStyle = createGlobalStyle`
${(props) => css`
  #gatsby-focus-wrapper,
  #___gatsby,
  html {
    height: 100%;
    margin: 0;
    /* display: contents; */
  }
`};
  body {
    height: 100%;
    font-family: 'jura';
    background-color: ${theme.backgroundColors.screen};
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;
  };
  h1,h2,h3,p {
    padding: 0;
    margin: 0;
    color: ${theme.colors.text1}
  }
  /* main {
    height: 100%;
    width: 100%;
    background-color: lightblue;
  } */
  `

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyle />
        {children}
      </React.Fragment>
    </ThemeProvider>
  )
}
