import { MOBILE_BREAK_POINT } from '@styles/styles'
import React from 'react'
import styled, { keyframes } from 'styled-components'

export const HomeBackground = () => {
  return (
    <Wrapper>
      <ProgressLinesContainer>
        <ProgressLine index={0} width={59} />
        <ProgressLine index={1} width={84} />
        <ProgressLine index={2} width={76} />
        <ProgressLine index={3} width={90} secondary />
        <ProgressLine index={4} width={69} />
      </ProgressLinesContainer>
      <ProgressCircle size={50} />
      <ProgressCircle size={40} delay={500} />
      {/* <DummyCalendarWrapper>
        {new Array(28).fill(0).map((_, i) => (
          <CalendarSquare key={i} {...{ i }} />
        ))}
      </DummyCalendarWrapper> */}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  /* background-color: teal; */
`

const ProgressLinesContainer = styled.div`
  position: absolute;
  top: 5vh;
  left: 0;
  width: 50vw;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    width: 60vw;
  }
`

interface ProgressLineProps {
  secondary?: boolean
  index: number
  width: number
}

const progressLineAnim = keyframes`
  0% {
    transform: translateX(-60%);
  }
  10% {
    transform: translateX(-30%);
  }
  30% {
    transform: translateX(-30%);
  }
  40% {
    transform: translateX(-20%);
  }
  70% {
    transform: translateX(-20%);
  }
  80% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(-10%);
  }
`

const ProgressLine = styled.div<ProgressLineProps>`
  height: 0.65em;
  width: ${(props) => props.width}%;
  margin-top: 1em;
  border-radius: 0 5px 5px 0;
  background-color: ${(props) =>
    props.secondary
      ? props.theme.colors.secondary
      : props.theme.colors.primary};
  animation: 8s ${progressLineAnim} infinite alternate;
  animation-delay: ${(props) => props.index * 200}ms;
`

const progressCircleAnim = keyframes`
    0% {
      transform: rotate(-100deg);
    }
    10% {
      transform: rotate(-90deg);
    }
    40% {
      transform: rotate(-90deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    70% {
      transform: rotate(-70deg);
    }
    80% {
      transform: rotate(-20deg);
    }
    90% {
      transform: rotate(-20deg);
    }
    100% {
      transform: rotate(-100deg);
    }
`

interface CircleProps {
  size: number
  delay?: number
}

const ProgressCircle = styled.div<CircleProps>`
  position: absolute;
  bottom: ${(props) => props.size / -2}vw;
  right: ${(props) => props.size / -2}vw;
  height: ${(props) => props.size}vw;
  width: ${(props) => props.size}vw;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    bottom: ${(props) => (props.size * 2) / -2}vw;
    right: ${(props) => (props.size * 2) / -2}vw;
    height: ${(props) => props.size * 2}vw;
    width: ${(props) => props.size * 2}vw;
  }
  border-radius: 50%;
  animation: 10s ${progressCircleAnim} infinite;
  animation-delay: ${(props) => props.delay ?? 0}ms;
  /* border: 1px solid ${(props) => props.theme.colors.secondary}; */
  border-width: 1em;
  border-color: transparent;
  border-left-color: ${(props) => props.theme.colors.primary};
  border-top-color: ${(props) => props.theme.colors.primary};
  border-style: solid;
`

const DummyCalendarWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: -2vw;
  height: 10vw;
  width: 30vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  /* background-color: rgba(255, 255, 255, 0.1); */
`

interface SquareProps {
  i: number
}

const squareAnim = keyframes`
  0% {
    opacity: 0
  }
  10% {
    opacity: 1
  }
  50% {
    opacity: 1
  }
  60% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
`

const CalendarSquare = styled.div<SquareProps>`
  height: 8%;
  width: calc((100% / 7) * 0.9);
  border-radius: 0.2em;
  /* margin: 0.5% 1%; */
  background-color: ${(props) =>
    !(props.i % 5)
      ? props.theme.colors.secondary
      : props.i % 3
      ? props.theme.colors.primary
      : `rgba(255,255,255,0.2)`};
  opacity: 0;
  animation: 10s ${squareAnim} infinite alternate;
  animation-delay: ${(props) => props.i * 250}ms;
`
