import styled from 'styled-components'

export const theme = {
  fontFamily: {
    special: 'jura',
    normal: 'montserrat',
  },
  /**Sizes are in em */
  fontSize: {
    desktop: {
      header: 2.5,
      large: 1.8,
      body: 1,
    },
    mobile: {
      header: 1.8,
      large: 1.2,
      body: 0.8,
    },
  },
  backgroundColors: {
    screen: '#151515',
  },
  colors: {
    primary: '#3877F4',
    secondary: '#00FF08',
    text1: 'rgba(255,255,255,0.95)',
    text2: 'rgba(255,255,255,0.8)',
  },
  widths: {
    /**Width in vw */
    maxMobile: 90,
    /**Width in px */
    maxDesktop: 1126,
  },
  /**Paddings are in px */
  paddings: {
    content: 20,
  },
}

export type MyTheme = typeof theme

/**Width is in px */
export const MOBILE_BREAK_POINT = 768

/**
 * A div that has its max-width set along with a breakpoint for mobile
 */
export const ContentWrapper = styled.div`
  max-width: ${(props) => props.theme.widths.maxDesktop}px;

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    max-width: ${(props) => props.theme.widths.maxMobile}vw;
  }
`
